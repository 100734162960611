import { ChainId, chainNames } from '@pancakeswap/chains'
import memoize from 'lodash/memoize'
import { Chain, bsc as bsc_ } from 'wagmi/chains'

export const CHAIN_QUERY_NAME = chainNames

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName.toLowerCase()]: chainId as unknown as ChainId,
    ...acc,
  }
}, {} as Record<string, ChainId>)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] : undefined
})

const bsc = {
  ...bsc_,
  rpcUrls: {
    ...bsc_.rpcUrls,
    public: {
      ...bsc_.rpcUrls.public,
      http: ['https://bsc-dataseed.binance.org/'],
    },
    default: {
      ...bsc_.rpcUrls.default,
      http: ['https://bsc-dataseed.binance.org/'],
    },
  },
} satisfies Chain

export const defiverse = {
  id: 16116,
  name: 'DeFiVerse',
  network: 'DeFiVerse',
  nativeCurrency: {
    decimals: 18,
    name: 'Oasys',
    symbol: 'OAS',
  },
  rpcUrls: {
    public: { http: ['https://rpc.defi-verse.org/'] },
    default: { http: ['https://rpc.defi-verse.org/'] },
  },
  blockExplorers: {
    etherscan: { name: 'DefiverseScan', url: 'https://scan.defi-verse.org/' },
    default: { name: 'DefiverseScan', url: 'https://scan.defi-verse.org/' },
  },
  contracts: {
    multicall3: {
      address: '0x4ceC8196246f4935372103b92224497fb3A5742f',
      blockCreated: 28562,
    },
  },
} as const satisfies Chain

export const defiverseTestnet = {
  id: 17117,
  name: 'DeFiVerse Testnet',
  network: 'DeFiVerse Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Oasys',
    symbol: 'OAS',
  },
  rpcUrls: {
    public: { http: ['https://rpc-testnet.defi-verse.org/'] },
    default: { http: ['https://rpc-testnet.defi-verse.org/'] },
  },
  blockExplorers: {
    etherscan: { name: 'DefiverseScan', url: 'https://scan-testnet.defi-verse.org/' },
    default: { name: 'DefiverseScan', url: 'https://scan-testnet.defi-verse.org/' },
  },
  contracts: {
    multicall3: {
      address: '0x673B7074258e8c0d74a3cB7870ecBc42663246eC',
      blockCreated: 14308,
    },
  },
} as const satisfies Chain

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.POLYGON_ZKEVM,
  ChainId.POLYGON_ZKEVM_TESTNET,
  ChainId.ZKSYNC,
  ChainId.ZKSYNC_TESTNET,
  ChainId.LINEA_TESTNET,
  ChainId.LINEA,
  ChainId.BASE,
  ChainId.BASE_TESTNET,
  ChainId.OPBNB,
  ChainId.OPBNB_TESTNET,
  ChainId.ARBITRUM_SEPOLIA,
  ChainId.BASE_SEPOLIA,
]

export const CHAINS = [
  defiverse,
  defiverseTestnet,
  // fantom,
  // fantomTestnet,
  // bsc,
  // bscTestnet,
  // mainnet,
  // goerli,
  // sepolia,
  // polygonZkEvm,
  // polygonZkEvmTestnet,
  // zkSync,
  // zkSyncTestnet,
  // arbitrum,
  // arbitrumGoerli,
  // arbitrumSepolia,
  // linea,
  // lineaTestnet,
  // base,
  // baseGoerli,
  // baseSepolia,
  // opBNB,
  // opBNBTestnet,
  // scrollSepolia,
]
