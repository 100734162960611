import { fantomTestnetTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { FarmConfigV3, SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedLps: FarmConfigV3[] = [
  {
    pid: 4,
    lpAddress: '0x846A252E9e12Dea26Ed31B83C7949EAAb4D1c469',
    token0: fantomTestnetTokens.weth,
    token1: fantomTestnetTokens.usdc,
    feeAmount: FeeAmount.MEDIUM,
  },
]
export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedLps,
  {
    pid: 2,
    lpAddress: '0x223deb50D1235B273FA33Bb01a91253D1a564B99',
    token0: fantomTestnetTokens.token1,
    token1: fantomTestnetTokens.token4,
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 3,
    lpAddress: '0xDA1849581d8531a181C1C26D2c84216fc05EDEc3',
    token0: fantomTestnetTokens.token1,
    token1: fantomTestnetTokens.token3,
    feeAmount: FeeAmount.MEDIUM,
  },
])
