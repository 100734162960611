import { defiverseTestnetTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { FarmConfigV3, SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedLps: FarmConfigV3[] = []
export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedLps,
  {
    pid: 1,
    lpAddress: '0x56bbd14b06649fc64ed53b7d8bda2100b4b66b2f',
    token0: defiverseTestnetTokens.weth,
    token1: defiverseTestnetTokens.cake,
    feeAmount: FeeAmount.MEDIUM,
  },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 1,
    lpSymbol: 'WOAS-GUNTEST LP',
    lpAddress: '0x56bbd14b06649fc64ed53b7d8bda2100b4b66b2f',
    token: defiverseTestnetTokens.weth,
    quoteToken: defiverseTestnetTokens.cake,
  },
  // {
  //   pid: 0,
  //   v1pid: 0,
  //   lpSymbol: 'WOAS-USDC LP',
  //   lpAddress: '0x0C25e54573F9bA81b7b45a520A8676eb06939789',
  //   token: defiverseTestnetTokens.weth,
  //   quoteToken: defiverseTestnetTokens.usdc,
  // },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))
export default farms
