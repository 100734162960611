import { useIsMounted } from "@pancakeswap/hooks";
import React from "react";
import { useMatchBreakpoints } from "../../contexts";
import { Flex } from "../Box";
import { Link } from "../Link";
import { StyledFooter, StyledList, StyledListItem, StyledSocialLinks, StyledText } from "./styles";
// import { LogoIcon, LogoWithTextIcon } from "../Svg";

import { vars } from "../../css/vars.css";
// import { LogoWithTextIcon } from "../Svg";
// import { LogoIcon } from "../Svg";
import { FooterProps } from "./types";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  const isMounted = useIsMounted();
  const { isXl } = useMatchBreakpoints();
  return (
    <>
      <StyledFooter
        data-theme="dark"
        p={["20px 16px 0px 16px", null, "54px 40px 0px 40px"]}
        position="relative"
        {...props}
        justifyContent="center"
      >
        <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
          <Flex
            order={[2, null, 1]}
            flexDirection={["column", "column", "column", "column", "row", "row"]}
            justifyContent="space-between"
            alignItems="flex-start"
            // mb={["42px", null, "0px"]}
            // mb={["22px", null, "16px"]}
          >
            {items?.map((item) => (
              <StyledList key={item.label}>
                <StyledListItem>{item.label}</StyledListItem>
                {item.items?.map(({ label, href, isHighlighted = false }) => (
                  <StyledListItem key={label}>
                    {href ? (
                      <Link
                        data-theme="dark"
                        href={href}
                        target="_blank"
                        rel="noreferrer noopener"
                        color={isHighlighted ? vars.colors.warning : "text"}
                        bold={false}
                      >
                        {label}
                      </Link>
                    ) : (
                      <StyledText>{label}</StyledText>
                    )}
                  </StyledListItem>
                ))}
              </StyledList>
            ))}
            {/* <Box display={["none", null, "block"]}>{isXl ? <LogoIcon /> : <LogoWithTextIcon width="160px" />}</Box> */}
            {/* <Box display={["none", null, "block"]}>{isXl ? <LogoWithTextIcon width="160px" /> : <LogoWithTextIcon width="160px" />}</Box>  */}
            {/* <Box display={["block", "block", "block"]}> {isXl ? <></> : <></>}</Box> */}
            {/* <Box display={["none", null, "block"]}>{isXl ? <LogoIcon /> : <LogoIcon />}</Box> */}
          </Flex>

          <StyledSocialLinks pb={["42px", null, "10px"]} mb={["0", null, "10px"]} />
          {/* <StyledToolsContainer
          data-theme="dark"
          order={[1, null, 3]}
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
        >
          <Flex order={[1, null, 2]} mb={["24px", null, "0"]} justifyContent="space-between" alignItems="center">
            <Box mr="20px">
               <CakePrice chainId={chainId} cakePriceUsd={cakePriceUsd} color="textSubtle" /></Box>
             <Button
              data-theme="dark"
              as="a"
              href={buyCakeLink}
              target="_blank"
              scale="sm"
              endIcon={<ArrowForwardIcon color="backgroundAlt" />}
            >
              {buyCakeLabel}
            </Button> 
          </Flex>
          

        </StyledToolsContainer> */}
        </Flex>
      </StyledFooter>

      <StyledFooter
        data-theme="dark"
        p={["10px 26px", null, "0px 55px 0px 55px"]}
        position="relative"
        // {...props}
        justifyContent="center"
      >
        © 2024 Gungnir DEX
        <br />
        <br />
        Made W/❤️ at the Defiverse and Oasys Ecosystem
        <br />
        <br />
        *Annual Percentage Yield (APY) data is provided from third party and publicly available information, is subject
        to change, may not be accurate or complete and may not reflect your actual earnings but rather the general
        network yields estimated to be applicable to all relevant network participants based on current conditions of
        the network, which may change. Presented rates are retrospective in nature and there is no guarantee that
        historic rates will represent current or future rates. APY data is provided for informational purposes only and
        should not be relied on.
      </StyledFooter>
    </>
  );
};

export default MenuItem;
